import * as React from 'react';
import { FC } from 'react';

import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { Layout } from '../components/partials/common';
import { SEO } from '../components/seo';
import { LocalizedPageProps } from '../utils/types';
import { HeroText } from '../components/partials/common/hero';
import RepeatingSections from '../components/sections/repeating-section';
import { ImageGallery } from '../components/ui/imageGallery';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const AboutUsPage: FC<LocalizedPageProps> = ({ pageContext }) => {
  const { t } = useTranslation('about');
  const { lang, alternateUrls } = pageContext;

  const hero: any = t('hero', {
    returnObjects: true,
  });

  const sections: any = t('sections', {
    returnObjects: true,
  });
  const sliderSettings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 1800,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    focusOnSelect: false,
    variableWidth: true,
  };

  return (
    <Layout
      pageContext={pageContext}
      mainClasses="background-new-light-green"
      navClasses="background-new-light-green light-green-fading"
    >
      <SEO
        lang={lang}
        title={t('meta.title')}
        description={t('meta.description')}
        alternateUrls={alternateUrls}
      />
      <HeroText
        title={hero.title}
        text={hero.text}
        ctaUrl={hero.ctaUrl}
        ctaText={hero.ctaText}
      />
      {/*      <section className='image_row_fw'>
        <div className="overfloater">
          <ImageGallery name="shore_website_about_overview_hero_01d" className='image_float_row' width='580' height="400" alt="mode image 1"/>
          <ImageGallery name="shore_website_about_overview_hero_01b" className='image_float_row' width='580' height="400" alt="mode image 1"/>
          <ImageGallery name="shore_website_about_overview_hero_01a" className='image_float_row' width='580' height="400" alt="mode image 1"/>
          <ImageGallery name="shore_website_about_overview_hero_01c" className='image_float_row is-hidden-mobile' width='580' height="400" alt="mode image 1"/>
        </div>
      </section>
 */}
      <Slider {...sliderSettings} className={` main-slider image_row_slider`}>
        <ImageGallery
          name="shore_website_about_overview_hero_01d"
          className="image_float_row"
          width="580"
          height="400"
          alt="mode image 1"
        />
        <ImageGallery
          name="shore_website_about_overview_hero_01b"
          className="image_float_row"
          width="580"
          height="400"
          alt="mode image 1"
        />
        <ImageGallery
          name="shore_website_about_overview_hero_01a"
          className="image_float_row"
          width="580"
          height="400"
          alt="mode image 1"
        />
        <ImageGallery
          name="shore_website_about_overview_hero_01c"
          className="image_float_row is-hidden-mobile"
          width="580"
          height="400"
          alt="mode image 1"
        />
      </Slider>

      <RepeatingSections data={sections} />
      <p>{t('notFoundMessage')}</p>
    </Layout>
  );
};

export default AboutUsPage;
